import React, { useState } from 'react';


function Job(props) {
    
    return (
      <div className="menloInnovationsWrapper">
        <h2 className="name" className="menloInnovationsName" onClick={() => {
            props.history.push('/');
          }}> 
            Sam Russell 
        </h2>
  			<h2 className="menloInnovationsInfo" onClick={() => {;
              }}>
  				: Previously a Software Developer for
  			</h2>
              
              
        <h2 className="usaaLink" style={{left: "26vw", top: "2vh"}} onClick={() => {
                window.open("https://usaa.com/", "_blank")
              }}>
  				USAA,
  			</h2>
        <h2 className="menloInnovationsLink" style={{left: "26vw", top: "14vh"}} onClick={() => {
                window.open("https://menloinnovations.com/", "_blank")
              }}>
  				Menlo Innovations.
  			</h2>
		</div>
    )
}

export default Job;