import './App.css';
import React, { useState } from 'react';
import { useHistory, Route } from 'react-router-dom';

import LinkedInLogo from "./images/LinkedInLogo.png";
import GithubLogo from "./images/GithubLogo.png";

import { motion } from 'framer-motion';


import Job from "./Pages/Job"


function App() {
  
  const [sectionsRevealed, setSectionsRevealed] = useState(false)
  
  const history = useHistory();
  
  
  
  const rainbowColors = [
    '#EB3A00',
    '#EB3A00',
    '#EB3A00',
  ]

  
  function sectionReveal() {
    let bodyClasses = document.body.classList.value
    
    if(bodyClasses.includes("sectionsRevealed")) {
      document.body.classList.remove("sectionsRevealed")
      setSectionsRevealed(false)
    } else {
      document.body.classList.add("sectionsRevealed")
      setSectionsRevealed(true)
    }
  }
  
  
  
  return (
    <div className="App wrapper">

      <Route
					path='/'
					exact={true}
					render={() => {
						return (
							<>      
                
                <h2 className="name"> 
                  SAM RUSSELL
                </h2>
                <h1 className="email" onClick={() => {
                  window.location.href = 'mailto:sam@samrussell.com';
                }}>
                  sam@samrussell.com
                </h1>
                
                
                <img className="LinkedInLogo" src={LinkedInLogo} onClick={() => {
                  window.open("https://www.linkedin.com/in/sam--russell/", "_blank")
                }}></img>
                
                <img className="GitHubLogo" src={GithubLogo} onClick={() => {
                  window.open("https://www.github.com/metacryst/", "_blank")
                }}></img>
              
                
              
                
                
                
                
              <h3 className={sectionsRevealed ? "organizations" : "hidden"}> 
                ORGANIZATIONS
              </h3>
                <h1 className="zero" style={{left: "44.9060%", top: "12.9658%"}} onClick={() => {
                   window.open('https://sun.museum/', "_blank")
                }}>
                  Museum of Captured Sun <span className={sectionsRevealed ? "subtext" : "hidden"}>- Founder (July 2022)</span>
                </h1>

                <h1 className="one" style={{left: "44.9060%", top: '17.7977%'}} onClick={() => {
                  history.push('/usaa');
                }}>
                  USAA <span className={sectionsRevealed ? "subtext" : "hidden"}>- Last Worked For (June-August 2022)</span>
                </h1>
                


              <h3 className={sectionsRevealed ? "websites" : "hidden"}> 
                WEBSITES
              </h3>
                <h1 className="two" style={{left: "44.9060%", top: "30.7692%"}} onClick={() => {
                  window.open('https://harvard-art.samrussell.com/', "_blank")
                }}>
                  "harvard art" <span className={sectionsRevealed ? "subtext" : "hidden"}>- Website to show pieces from the Harvard Art Museum</span>
                </h1>
                
                <h1 className="three" style={{left: "44.9060%", top: "34.7578%"}} onClick={() => {
                  window.open('https://paperclip-house.samrussell.com/', "_blank");
                }}>
                  paperclip// <span className={sectionsRevealed ? "subtext" : "hidden"}>- Bartering website, which matches people for trades like Tinder</span>
                </h1>
                
                <h1 className="four" style={{left: "44.9060%", top: "38.8889%"}} onClick={() => {
                  window.open('https://wip-gallery.samrussell.com/', "_blank");
                }}>
                  [wip] <span className={sectionsRevealed ? "subtext" : "hidden"}>- Virtual art gallery where anyone can make an account and submit pieces</span>
                </h1>
                
                
                
              <h3 className={sectionsRevealed ? "books" : "hidden"}> 
                BOOKS
              </h3>
                <h1 className="six" onClick={() => {
                  history.push('/whowillsurvive');
                }}>
                  Who Will Survive in America <span className={sectionsRevealed ? "subtext" : "hidden"}>- Book about late-stage capitalism in the USA, published 2017</span>
                </h1>
                
                <h1 className="seven" onClick={() => {
                  history.push('/maga');
                }}>
                  Make America Great Again <span className={sectionsRevealed ? "subtext" : "hidden"}>- Book about Kanye West's foray into presidential politics, published 2018</span>
                </h1>
                
                
              <h3 className={sectionsRevealed ? "songs" : "hidden"}> 
                SONGS
              </h3>
                
                <h1 className="nine" onClick={() => {
                  window.open("https://open.spotify.com/track/12S8FcPoIPcS7bbe8OYt4X?si=64191ef1a9924c47", "_blank")
                }}>
                  A Letter 
                </h1>
                
                <h1 className="ten" onClick={() => {
                  window.open("https://open.spotify.com/track/3w7XruCEgS7lBY4WZ8msCy?si=73f5ce5ed94e4861", "_blank")
                }}>
                  Zeus
                </h1>
                
                
              <h3 className={sectionsRevealed ? "classes" : "hidden"}> 
                CLASSES
              </h3>
                <h1 className="eleven" onClick={() => {
                  history.push('/physics');
                }}>
                  Physics I
                </h1>
                <h1 className="twelve" onClick={() => {
                  history.push('/calculus');
                }}>
                  Multivariable Calculus
                </h1>
                <h1 className="thirteen" onClick={() => {
                  history.push('/linear');
                }}>
                  Linear Algebra
                </h1>
                <h1 className="fourteen" onClick={() => {
                  history.push('/discrete');
                }}>Discrete Structures
                </h1>
                
                
              
              
                <motion.button 
          				animate={{
          					backgroundColor: rainbowColors,
          				}}
          				transition={{ 
          					backgroundColor: {type: 'tween', duration: 1, yoyo: Infinity},
          					// default: duration: 2
          				}}
          				whileHover={{ scale: 1.9, rotate: -180, duration: 2 }}
          		 	  whileTap={{
          			    scale: 0.8,
          			    rotate: -180,
          			    borderRadius: "100%"
          			  }}
          				
          				className="infoRevealButton" 
          				onClick={sectionReveal}
        				>
                    <div className="spanContainer">
        						<motion.span 
          						animate={{
          							color: rainbowColors,
          						}}
          						transition={{ 
          							type: 'tween', duration: 1, yoyo: Infinity 
          						}}>
        							▧ ▨  <br />
                      ▧ ▨  <br />
                      ▧ ▨  <br />
                      ▧ ▨  <br />
                      ▧ ▨  <br />
                      ▧ ▨  <br />
        						</motion.span>
                    
                    
        						<motion.span 
        						animate={{
        							color: rainbowColors,
        						}}
        						transition={{ 
        							type: 'tween', duration: 1, yoyo: Infinity 
        						}}>
        							▧ ▨ 
        						</motion.span>
                    </div>
        				</motion.button>
            </>
						);
					}}
				/>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
      
        

      
      
        
        <Route
					path='/usaa'
					exact={true}
					render={() => {
						return (
              <>
							<Job history={history}/>
              </>
						);
					}}
				/>
        
        <Route
					path='/parchment'
					exact={true}
					render={() => {
						return (
							<div className="wrapper">
                <h2 className="name" onClick={() => {
                  history.push('/')
                }}> 
                  Sam Russell 
                </h2>
								<div className="parchmentInfo">
									coming soon. A new Text Editor. 
								</div>
							</div>
						);
					}}
				/>
        
        
        <Route
					path='/whowillsurvive'
					exact={true}
					render={() => {
						return (
							<div className="whoWillSurviveWrapper">
                <h2 className="name" onClick={() => {
                  history.push('/')
                }}> 
                  Sam Russell
                </h2>
                <h2 className="title" onClick={() => {
                  window.open("https://www.amazon.com/Who-Will-Survive-America-Capitalism/dp/1541389964", "_blank")
                }}> 
                  WHO WILL SURVIVE IN AMERICA
                </h2>
								<div className="whoWillSurviveInfo">
                © December 2017 - a book about the 1970s Marxist theory of Late Capitalism and why, 50 years later, it has begun to rear its head. 
								</div>
							</div>
						);
					}}
				/>
        
        
        <Route
					path='/maga'
					exact={true}
					render={() => {
						return (
							<div className="magaWrapper">
                <h2 id="magaName" className="name" onClick={() => {
                  history.push('/')
                }}> 
                  Sam Russell
                </h2>
                <h2 className="magaTitle" onClick={() => {
                  window.open("https://www.amazon.com/Make-America-Great-Again-Humanity/dp/1719549389/ref=sr_1_4?dchild=1&keywords=make+america+great+again+sam+russell&qid=1613865944&sr=8-4", "_blank")
                }}> 
                  MAKE AMERICA GREAT AGAIN
                </h2>
                <div className="magaInfo">
                © June 2018 - a book about Kanye West's 2018 presidential campaign 
								</div>
							</div>
						);
					}}
				/>
        
        <Route
					path='/physics'
					exact={true}
					render={() => {
						return (
							<div className="wrapper">
                <h2 className="name" onClick={() => {
                  history.push('/')
                }}> 
                  Sam Russell
                </h2>
                <h2 className="physicsTitle"> 
                  //      ____    __
//     / __ \  / /_    __  __  _____  (_) _____  _____
//    / /_/ / / __ \  / / / / / ___/ / / / ___/ / ___/
//   / ____/ / / / / / /_/ / (__  ) / / / /__  (__  )
//  /_/     /_/ /_/  \__, / /____/ /_/  \___/ /____/
//                   /____/
                </h2>
                <div className="physicsInfo">
                  ENERGY
                  <br></br>
                  <br></br>
                  mo t  i   o     n
                  <br></br>
                  <br></br>
                  || forces ||
                  <br></br>
                  <br></br>
                  --momentum-->
                  <br></br>
                  <br></br>
                  fluids~~~
                  <br></br>
                  <br></br>
                  ~waves~
                  <br></br>
                  <br></br>
                  `gases`
								</div>
							</div>
						);
					}}
				/>
        
        <Route
					path='/calculus'
					exact={true}
					render={() => {
						return (
							<div className="wrapper">
                <h2 className="name" onClick={() => {
                  history.push('/')
                }}> 
                  Sam Russell
                </h2>
                <h2 className="calcTitle"> 
                  <pre>
                  +------+.      +------+       +------+       +------+      .+------+<br></br>
                  |`.    | `.    |\     |\      |      |      /|     /|    .' |    .'|<br></br>
                  |  `+--+---+   | +----+-+     +------+     +-+----+ |   +---+--+'  |<br></br>
                  |   |  |   |   | |    | |     |      |     | |    | |   |   |  |   |<br></br>
                  +---+--+.  |   +-+----+ |     +------+     | +----+-+   |  .+--+---+<br></br>
                  &nbsp;`. |    `.|    \|     \|     |      |     |/     |/    |.'    | .' <br></br>
                  &nbsp;  `+------+     +------+     +------+     +------+     +------+'   <br></br>
                  </pre>
                </h2>
                <div className="integrals">
                  ∫∫∫∫∫∫∫∫∫∫∫∫∫∫
								</div>
                <div className="integrals" id="int2">
                  ∫∫∫∫∫∫∫∫∫∫∫
								</div>
                <div className="integrals" id="int3">
                  ∫∫∫∫∫∫∫∫∫∫∫
								</div>
                <div className="integrals" id="int4">
                  ∫∫∫∫∫∫∫∫∫∫∫
								</div>
                <div className="integrals" id="int5">
                  ∫∫∫∫∫∫∫∫∫∫∫
								</div>
                <div className="integrals" id="int6">
                  ∫∫∫∫∫∫∫∫∫∫∫
								</div>
                <div className="integrals">
                  ∫∫∫∫∫∫∫
								</div>
                <div className="lineIntegrals">
                  ∮∮∮∮∮∮∮∮∮∮∮∮
								</div>
                <div className="info">
                  partial differentiation -- 
                  <br></br>
                  3D graphing
                  <br></br>
                  gradients
                  <br></br>
                  directional derivatives
                  <br></br>
                  vector fields
								</div>
							</div>
						);
					}}
				/>
        
        <Route
					path='/linear'
					exact={true}
					render={() => {
						return (
							<div className="wrapper">
                <h2 className="name" onClick={() => {
                  history.push('/')
                }}> 
                  Sam Russell
                </h2>
                <h2 className="linearTitle"> 
                <pre>
                  1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  <span className="innerText">M A T R I X</span> 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  <span className="innerText">T R A N S F</span> 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 <span className="innerText">O</span> 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 <span className="innerText">R</span> 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 <span className="innerText">M</span> 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
                  0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0<br></br>
    
                  </pre>
                </h2>
							</div>
						);
					}}
				/>
        
        
        <Route
					path='/discrete'
					exact={true}
					render={() => {
						return (
							<div className="wrapper">
                <h2 className="name" onClick={() => {
                  history.push('/')
                }}> 
                  Sam Russell
                </h2>
                <h2 className="discreteTitle">
                <pre>
                You have just arrived on an island inhabited<br></br> 
                by two kinds of people: knights who <br></br>
                always tell the truth, and knaves who always lie.<br></br> 
                Every inhabitant of the island is either <br></br>
                a knight or a knave, and <br></br> 
                everyone knows which inhabitants are knights <br></br> 
                and which are knaves. You are <br></br> 
                a stranger on the island, and you do not know <br></br>
                who is a knight and who is a knave.<br></br>
                
                <br></br>
                It is rumored that there is gold on the island. <br></br>
                You ask an inhabitant if there really is <br></br>
                gold on the island. <br></br>
                He responds, “There is gold on this island if and<br></br>
                only if I am a knight.”<br></br>
                <br></br>
                Is there gold on the island?<br></br>
                </pre>
                </h2>
							</div>
						);
					}}
				/>
        

    </div>
  );
}

export default App;